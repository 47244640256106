import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

export default class extends Controller {
  static values = {
    url: String
  }

  connect() {
    console.info("Hello, from claim reason Controller!", this.element)
  }

  change(event) {
    const url = new URL(this.urlValue)
    const params = new URLSearchParams(url.search)
    params.append('reason', event.target.selectedOptions[0].value)
    
    get(url, {
      query: params,
      responseKind: "turbo-stream"
    })
  }
}
