import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    console.log("Detail controller connected");
  }

  button = document.querySelector('[data-name="submit-button"]');
  isNative = this.button && this.button.dataset.isNative;
  focus() {
    if (this.isNative) {
      this.button.style.marginBottom = '260px';
      this.button.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  blur() {
    if (this.isNative) {
      this.button.style.marginBottom = '10px';
    }
  }
}
