// Borrowed from https://gist.github.com/leehericks/cbba7a305dff07daa57ae487380656f9

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  
  navigate(event) {
    let location = this.extract_location(event.target)
    Turbo.visit(location)
  }

  extract_location(select) {
    return select.options[select.selectedIndex].value
  }
}
