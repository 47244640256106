import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    console.info("Hello, from button Controller!", this.element)
  }

  emitMessage(event) {
    window.top.postMessage('clicked-button', '*')
  }
}